import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Translation from 'components/data/Translation';
import PublishButton from 'components/bricks/components/publish/components/publish-button';
import Button from 'components/ui-components-v2/Button';
import { Brick, BrickOutputAction, BrickSetup } from 'components/bricks/types/brick.type';
import { BrickPublishJob } from 'components/bricks/types/brick-publish.type';
import { BrickPublishJobData, useBricksPublish } from 'components/bricks/hooks/useBricksPublish';
import EmptyState from 'components/ui-components-cape/EmptyState';
import Illustration from 'components/ui-components-cape/Illustration';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import Request from 'components/data/Request';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import IconButton from 'components/ui-components-v2/IconButton';
import User from 'components/data/User';
import ComponentStore from 'components/data/ComponentStore';
import { BricksComponentStore } from 'components/bricks/types/bricksComponentStore.type';
import OutputHelper from 'components/bricks/helpers/output.helper';
import { useBricksNavigation } from 'components/bricks/hooks/useBricksNavigation';
import { PublishTabFilters } from './filters';
import { OutputTabHelpers } from '../helpers/output-tab.helper';
import { PublishStatus } from './status';
import { BrickPublishInfo } from './publish-info';
import { PublishValidationError, TabState } from '../types/PublishTab.type';
import DownloadButton from '../../download-button/components';

import './../styles/publish-content.scss';

interface Props {
    foundBricks: Brick[];
    selectedBrick: string;
    validationErrors: PublishValidationError[];
    activeTab: number;
    tabStates: TabState[];
    setTabStates: (tabStates: TabState[]) => void;
    currentBricks: Brick[];
    setSelectedBrick: (value: string) => void;
}

const OutputContent: React.FC<Props> = ({
    foundBricks,
    validationErrors,
    activeTab,
    selectedBrick,
    tabStates,
    setTabStates,
    currentBricks,
    setSelectedBrick
}) => {
    const history = useHistory();
    const { navigateToItem } = useBricksNavigation();
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
    const [loading, setLoading] = useState(true);
    const [showWarning, setShowWarning] = useState(true);

    // determine the channel
    const channel = useMemo(() => OutputTabHelpers.getPublishChannelForBricks(currentBricks), [currentBricks]);

    // Fetch login status only if channel is defined
    const fetchLoginStatus = useCallback(async () => {
        if (!channel) return;
        setLoading(true);
        try {
            const response = (await Request.post('publishing/accountManagement', {
                channel: channel,
                type: 'profiles'
            })) as { success: number; data: any };

            if (response?.data && response.success !== undefined) {
                setIsLoggedIn(response.success !== 0);
            } else {
                setIsLoggedIn(false);
            }
        } catch (error) {
            console.error('Error checking login status:', error);
            setIsLoggedIn(false);
        } finally {
            setLoading(false);
        }
    }, [channel]);

    useEffect(() => {
        if (channel) {
            fetchLoginStatus();
        }
    }, [channel]);

    // based on the found bricks we need to get the selected publish job and brick
    // these are used to filter the bricks and publish jobs
    const { selectedPublishId } = (() => {
        let publishId = 'last-publish';

        const publishJobs = foundBricks
            .map((brick) => brick.publish?.default)
            .filter((publishJob) => publishJob)
            .flat() as BrickPublishJob[];

        if (!publishJobs.length) {
            publishId = 'current-publish';
        }

        return {
            selectedPublishId: tabStates[activeTab]?.publishId || publishId
        };
    })();

    /**
     * set the selected publish job in the tab state
     * @param value the selected publish job
     */
    const setSelectedPublishId = (value: string) => {
        const tabState = tabStates[activeTab];

        if (!tabState) {
            tabStates[activeTab] = { publishId: value, selectedBrick: 'all' };
        } else {
            tabState.publishId = value;
        }

        setTabStates([...tabStates]);
    };

    // get all the publish jobs that is linked to the filtered bricks and the selected publish job
    // if it is last publish we need to get the last publish job of all the current bricks
    // if it is current publish we need to get the current publish job of all the current bricks
    const { publishJobs: allJobs } = useBricksPublish(
        currentBricks.map((brick) => brick.id),
        selectedPublishId
    );

    // Filter out the download jobs that are not from the user
    const publishJobs = allJobs?.filter((job) => {
        const bricksComponentStore: BricksComponentStore | undefined = ComponentStore.get('Bricks');
        const publish = bricksComponentStore?.publish;

        if (!publish) return false;

        const brickId = currentBricks[0].id;
        const outputAction = publish[job.publishId]?.bricks[brickId]?.outputAction;
        const isJobFromUser = publish[job.publishId]?.bricks[brickId]?.createdBy === Number(User.get('id'));

        // Filter out the download jobs that are not from the user
        if (outputAction === 'download' && !isJobFromUser) return false;
        return true;
    });

    /**
     * This function is called when a publish job is started and it makes sure that the filters are updated to the last publish job
     * This makes sure that the user sees the publish job that is started instead of the current publish job state
     *
     * @param publishJobs publish jobs that are started
     */
    const onPublish = (publishJobs: BrickPublishJobData[]) => {
        const hasStartedPublish = publishJobs.some((publishJob) => currentBricks.some((brick) => brick.id === publishJob.brickId));

        if (!hasStartedPublish) return;

        setSelectedPublishId('last-publish');
    };

    /**
     *
     * @param brick
     * @param outputActions
     * @returns
     */
    const checkBrickForOutputAction = (brick: Brick, outputAction: BrickOutputAction) => {
        const brickOutpuAction = BrickHelpers.getBrickData<BrickSetup>(brick.subType, 'setup').outputAction;
        return brickOutpuAction === 'all' || outputAction === brickOutpuAction;
    };

    const showBlockerButton = !publishJobs?.length && validationErrors && validationErrors.length === 1;
    const showPublishButton =
        !publishJobs?.length &&
        (!validationErrors || validationErrors.length === 0) &&
        currentBricks.some((brick) => checkBrickForOutputAction(brick, 'publish'));

    const showDownloadButton =
        !publishJobs?.length &&
        (!validationErrors || validationErrors.length === 0) &&
        currentBricks.some((brick) => checkBrickForOutputAction(brick, 'download'));

    const showPublishStatus = !showPublishButton && !showBlockerButton && !showDownloadButton;
    const outputAction = OutputHelper.getOutputActionOfBricks(currentBricks);

    // Block rendering until login status is determined
    if (channel && (isLoggedIn === null || loading)) {
        return (
            <div className="bricks-output-tab__tab-content__placeholder">
                <CircularProgress />
            </div>
        );
    }

    // If not logged in, display the empty state
    if (channel && !isLoggedIn && showWarning) {
        return (
            <div className="bricks-output-tab__tab-content__placeholder">
                <Tooltip title={Translation.get('actions.close')} enterDelay={300}>
                    <IconButton className="bricks-output-tab__tab-content__placeholder__close-button" size="small" onClick={() => setShowWarning(false)}>
                        <Icon fontSize="small">close</Icon>
                    </IconButton>
                </Tooltip>
                <EmptyState
                    primaryText={Translation.get('output.publish.notLoggedIn', 'bricks')}
                    secondaryText={OutputTabHelpers.generateAccountNotConnectedTitle(currentBricks)}
                    illustration={<Illustration illustration="fallback" color="disabled" />}
                />
                <div className="bricks-output-tab__tab-content__placeholder__actions">
                    <Button variant="contained" color="primary" onClick={() => history.push('/settings/ad-management')}>
                        {Translation.get('output.publish.loginAdManagmenet', 'bricks')}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className="bricks-output-tab__tab-content">
            <PublishTabFilters
                currentBricks={foundBricks}
                selectedBrick={selectedBrick}
                setSelectedBrick={setSelectedBrick}
                setSelectedPublishId={setSelectedPublishId}
                selectedPublishId={selectedPublishId}
            />
            {(showPublishButton || showDownloadButton) && (
                <div className="bricks-output-tab__tab-content__placeholder">
                    <EmptyState
                        primaryText={OutputTabHelpers.generatePublishTitle(currentBricks, outputAction)}
                        secondaryText={Translation.get(`output.${outputAction}.readyToOutput`, 'bricks')}
                        illustration={<Illustration illustration="publish" />}
                    />
                    <div className="bricks-output-tab__tab-content__placeholder__actions">
                        {showPublishButton && <PublishButton bricks={currentBricks} variant="contained" onPublish={onPublish} />}
                        {showDownloadButton && (
                            <DownloadButton bricks={currentBricks} variant={showPublishButton ? 'outlined' : 'contained'} onFinishDownload={onPublish} />
                        )}
                    </div>
                </div>
            )}

            {showBlockerButton && (
                <div className="bricks-output-tab__tab-content__placeholder">
                    <EmptyState
                        primaryText={Translation.get('output.validations.blockMessage', 'bricks')}
                        secondaryText={validationErrors[0].title}
                        illustration={<Illustration illustration="publish" color="disabled" />}
                    />
                    <div className="bricks-output-tab__tab-content__placeholder__actions">
                        <Button
                            variant="outlined"
                            onClick={() => OutputTabHelpers.handlePublishResultButton(validationErrors[0], currentBricks[0], navigateToItem)}>
                            {validationErrors[0].buttonName}
                        </Button>
                    </div>
                </div>
            )}
            {showPublishStatus && <PublishStatus currentBricks={currentBricks} validationErrors={validationErrors} selectedPublishId={selectedPublishId} />}
            <BrickPublishInfo currentBricks={currentBricks} selectedPublishId={selectedPublishId} />
        </div>
    );
};

export default OutputContent;
