import React, { useState } from 'react';
import { Brick } from 'components/bricks/types/brick.type';
import { useBricksPublish } from 'components/bricks/hooks/useBricksPublish';
import OutputHelper from 'components/bricks/helpers/output.helper';
import Translation from 'components/data/Translation';
import List from 'components/ui-components-v2/List';
import ListItem from 'components/ui-components-v2/ListItem';
import Dialog from 'components/ui-components/Dialog';
import PublishService from 'components/bricks/services/publish.service';
import { useBricksNavigation } from 'components/bricks/hooks/useBricksNavigation';
import { PublishSection } from '../publish-section';
import { PublishSectionDetails } from '../publish-details';
import { PublishSubSection } from '../publish-subsection';
import { PublishSummaryBar } from './components/publish-summary-bar';
import { PublishInfoHelper } from './helpers/publish-info.helper';
import { OutputTabHelpers } from '../../helpers/output-tab.helper';
import { PublishResult } from '../../types/PublishTab.type';

import './styles/main.scss';

interface Props {
    currentBricks: Brick[];
    selectedPublishId?: string;
}

const BrickPublishInfo = ({ currentBricks, selectedPublishId }: Props) => {
    const { navigateToItem } = useBricksNavigation();
    const brickIds = currentBricks.map((brick) => brick.id);
    const { publishJobs } = useBricksPublish(brickIds, selectedPublishId);
    const [openDialog, setOpenDialog] = useState(false);
    const [detailMessages, setDetailMessages] = useState<string[]>([]);

    const multipleBricks = currentBricks.length > 1;

    const validationErrors = OutputTabHelpers.getValidationErrors(currentBricks);

    if (!publishJobs?.length) {
        if (validationErrors.length === 1 || validationErrors.length === 0) return null;
        //classes={{ root: 'publish-info__validation-results' }}
        return (
            <PublishSection title={`Summary`}>
                <PublishSubSection title={`blockers (${validationErrors.length})`}>
                    <PublishSectionDetails
                        items={validationErrors}
                        hasMultipleBricks={multipleBricks}
                        onActionClick={(item, brick) => brick && OutputTabHelpers.handlePublishResultButton(item, brick, navigateToItem)}
                    />
                </PublishSubSection>
            </PublishSection>
        );
    }

    const publishResults = PublishInfoHelper.convertPublishJobsToPublishResults(publishJobs);
    const reports = PublishInfoHelper.convertPublishJobsAsPublishResults(publishJobs, 'reports');
    const errors = PublishInfoHelper.convertPublishJobsAsPublishResults(publishJobs, 'errors');
    const warnings = PublishInfoHelper.convertPublishJobsAsPublishResults(publishJobs, 'warnings');
    const outputAction = OutputHelper.getOutputActionOfBricks(currentBricks);

    const handleViewDetailsClick = async (item: PublishResult, messageType: string, messageIdentifier: string) => {
        if (!item.jobId) return;

        const messages = await PublishService.getPublishJobsDetailedMessages(item.jobId, messageType, messageIdentifier);
        setDetailMessages(messages);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => setOpenDialog(false);

    return (
        <>
            {publishResults.length > 0 && (
                <PublishSection title={Translation.get(`publishTab.publishSection.results.${outputAction}`, 'bricks')}>
                    <PublishSectionDetails
                        items={publishResults}
                        classes={{ root: 'publish-info__results-section' }}
                        hasMultipleBricks={multipleBricks}
                        onActionClick={(item, brick) => brick && OutputTabHelpers.handlePublishResultButton(item, brick, navigateToItem)}
                    />
                </PublishSection>
            )}
            {(reports.length > 0 || errors.length > 0 || warnings.length > 0) && (
                <PublishSection title={`Summary`}>
                    <PublishSummaryBar reports={reports.length} errors={errors.length} warnings={warnings.length} />
                    {reports.length > 0 && (
                        <PublishSubSection title={`Reports`} severity="success">
                            <PublishSectionDetails
                                items={reports}
                                severity="success"
                                hasMultipleBricks={multipleBricks}
                                onActionClick={(item) => item.messageIdentifier && handleViewDetailsClick(item, 'report', item.messageIdentifier)}
                            />
                        </PublishSubSection>
                    )}
                    {errors.length > 0 && (
                        <PublishSubSection title={`Errors`} severity="error">
                            <PublishSectionDetails
                                items={errors}
                                severity="error"
                                hasMultipleBricks={multipleBricks}
                                onActionClick={(item) => item.messageIdentifier && handleViewDetailsClick(item, 'error', item.messageIdentifier)}
                            />
                        </PublishSubSection>
                    )}
                    {warnings.length > 0 && (
                        <PublishSubSection title={`Warnings`} severity="warning">
                            <PublishSectionDetails
                                items={warnings}
                                severity="warning"
                                hasMultipleBricks={multipleBricks}
                                onActionClick={(item) => item.messageIdentifier && handleViewDetailsClick(item, 'warning', item.messageIdentifier)}
                            />
                        </PublishSubSection>
                    )}
                </PublishSection>
            )}
            <Dialog classes={{ content: 'publish-info__dialog' }} open={openDialog} title={Translation.get('labels.details')} onClose={handleCloseDialog}>
                <List>
                    {detailMessages.map((message, index) => (
                        <ListItem key={index}>{message}</ListItem>
                    ))}
                </List>
            </Dialog>
        </>
    );
};

export { BrickPublishInfo };
